import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import SkillLogo from './skill-logo';

const WorkList = () => {
  const {
    allMdx: { nodes: entries },
  } = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fields: { contentType: { eq: "work" }, name: { ne: "index" } }
        }
        sort: { fields: frontmatter___dates, order: DESC }
      ) {
        nodes {
          frontmatter {
            ...Work
          }
          fields {
            slug
          }
        }
      }
    }
  `);
  return entries.map((entry, i) => {
    const { image, dates, employer, role, skills } = entry.frontmatter;
    return (
      <Link key={i} className="list-item link-container" to={entry.fields.slug}>
        <div className="item-thumbnail">
          {image ? (
            image.childImageSharp ? (
              <Img {...image.childImageSharp} />
            ) : (
              <img src={image.publicURL} alt={employer} />
            )
          ) : null}
        </div>
        <div className="item-content">
          <div className="item-details">
            <h2>
              {employer}{' '}
              <small>
                <em>{dates}</em>
              </small>
            </h2>
            <h4 className="work-role">{role}</h4>
          </div>
          <div className="item-skills">
            {skills
              ? skills.map((skill, j) => (
                  <SkillLogo key={j} name={skill} size={3} />
                ))
              : null}
          </div>
        </div>
      </Link>
    );
  });
};

export default WorkList;
